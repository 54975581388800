.fp-watermark {
	display: none;
}

html {
	font-size: 16px;
}

body {
	position: relative;
	min-width: 320px;
	margin: auto;
	background: var(--black);
	color: var(--white);

	overflow-x: hidden;
}

.swiper-wrapper {
	left: 0 !important;
}

#site {
	position: relative;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

.main {
	flex-grow: 1;
}

%default-transition {
	transition: ease-in-out 0.25s;
}

section {
	position: relative;
}

#bold-credits {
	width: 150px;
	transition: ease-in-out 0.25s;

	&::before,
	&::after {
		display: none;
	}

	&:hover {
		transform: scale(1.1);
	}
}
.sticky:before,
.sticky:after {
	content: "";
	display: table;
}

.first-section {
	background: radial-gradient(
			50% 50% at 50% 50%,
			rgba(53, 48, 48, 0.4) 0%,
			rgba(53, 48, 48, 0.6) 100%
		),
		linear-gradient(90deg, #353030 0%, rgba(53, 48, 48, 0) 100%),
		var(--image-bg), #ffffff;
}

#swiper-gallery {
	.swiper-slide {
		padding-bottom: 87.5%;
		position: relative;
		height: 0;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			object-fit: cover;
		}

		&.swiper-slide-next {
			@mixin min-media 1024 {
				z-index: 99 !important;
			}
		}

		@mixin min-media 1024 {
			padding-bottom: 87.1544715447%;
		}
	}
}

#swiper-popular-goods {
	--swiper-pagination-bullet-inactive-color: var(--gray-300);
	--swiper-pagination-bullet-inactive-opacity: 1;
	--swiper-pagination-color: var(--orange);
}

input {
	@mixin transparent-bg-input;
}

.swiper-pagination-bullets-dynamic {
	display: flex;
	justify-content: center;

	.swiper-pagination-bullet {
		flex-shrink: 0;
	}
}


/*
.sidebar {
	position: relative;
	width: min(19.375rem, 50%);
	flex-shrink: 0;
	margin-left: 1.25rem;
	height: auto;
}

#content-sticky {
	width: calc(100% - min(19.375rem, 50%) - 1.25rem);
}

@media screen and (max-width: 360px) {
	.sidebar {
		width: 100%;
		margin-bottom: 30px;
	}

	#content-sticky {
		width: 100%;
	}
}*/


#categories-menu {
	& * {
		transition: color ease-in-out 0.25s;
		color: var(--white);

		&:hover {
			color: var(--orange);
		}
		&.active {
			color: var(--orange);
			list-style-color: var(--orange);

			& > a {
				color: var(--orange);
				text-decoration: underline;
				text-underline-offset: 2px;
			}
		}
	}
}

.thumbs-slider-bottom {
	.swiper-slide {
		border: solid 2px transparent;
		transition: border-color ease-in-out 0.25s;

		&.swiper-slide-thumb-active {
			border-color: var(--orange);
		}
	}
}

.thumbs-slider__nav {
	.swiper-pagination-current {
		color: var(--orange);
	}
}

#sidebar-inner {
	@mixin scrollbar;
}