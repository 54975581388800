/*----------------------- Common desktop header -----------------------*/
.header {
	display: flex;
	align-items: center;

	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	padding: ac(1.5rem, 1rem) 0;

	height: var(--header-height);

	transition: all ease-in-out 0.25s;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;

		left: 100%;
		right: auto;
		bottom: 0;
		top: 0;

		background: var(--black);

		transition: left 0.25s ease-in-out;
	}

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 0;

		transform: translateX(-50%);

		width: calc(100% - 2.5rem);
		height: 1px;
		background: var(--gray-300);

		transition: width 0.25s ease-in-out;

		@mixin min-media 1024 {
			width: calc(100% - 6rem);
		}

		@mixin min-media 1280 {
			display: none;
		}
	}

	&.active::before {
		left: 0;
	}

	&.scrolled {
		background: color-mod(var(--black) a(60%));
		backdrop-filter: blur(10px);

		&::after {
			width: 100%;
		}

		.logo {
			transform: scale(0.8);

			&:hover {
				transform: scale(0.85);
			}
		}
	}

	.logo {
		height: ac(5rem, 3rem);
		width: ac(15.9375rem, 9.375rem);
		flex-shrink: 0;
		z-index: 1;

		transition: setTransition(transform);

		transform-origin: left;

		&::before {
			display: none;
		}

		&:hover {
			transform: scale(1.06);
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	.navbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.burger {
		display: none;
	}
	.navbar-nav {
		display: flex;
		flex-direction: column;
	}
	.menu {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.menu-item {
		position: relative;

		@mixin min-media 1280 {
			display: flex;
			align-items: center;
		}

		&.dropdown {
			& > .menu-link {
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
				&::after {
					content: "\e900";
					font-family: "icomoon";
					font-size: 24px;
					transition: transform ease-in-out 0.25s;
					@media (--mobile-menu-start-point) {
						content: none;
					}
				}
			}

			.arrow-dropdown {
				font-size: 24px;
				transition: transform ease-in-out 0.25s, color .25s ease;
				display: inline-flex;
				cursor: pointer;
				transform: translateY(0.075rem);

				&:hover {
					color: var(--orange);
				}

				@media (--mobile-menu-end-point) {
					display: none;
				}

				&.active {
					transition-delay: .2s;
					transform: rotate(180deg) translateY(-0.275rem);
				}
			}

			&.active {
				& > .menu-link {
					&::after {
						transform: rotate(180deg) translateY(-0.175rem);
					}
				}
			}
		}
	}
	&:not(.header-mobile) {
		.menu-item {
			@media (--mobile-menu-end-point) {
				flex-shrink: 0;
			}
			&:hover {
				@media (--mobile-menu-end-point) {
					.dropdown-menu {
						display: block;
					}
				}
			}
		}
	}

	.dropdown-menu {
		position: absolute;
		top: 100%;
		right: 0;
		min-width: 100%;
		margin-top: 0;
		box-sizing: content-box;
		display: none;

		@mixin min-media 1280 {
			max-height: 15rem;
			width: 10rem;
			overflow-y: auto;

			border-radius: 0.5rem 0 0 0.5rem;
			background: var(--black);
			padding: 0.75rem 1rem;
			margin-top: 0;
			z-index: 5;

			@mixin scrollbar;
		}

		&.active {
			@media (--mobile-menu-start-point) {
				margin-top: 1.5rem;
			}
		}

		li {
			.menu-link {
				padding: 5px 0;
				text-transform: capitalize;
			}
		}
	}
}
/* end of Common desktop header */

/*----------------------- Common desktop header Mobile HEADER -----------------------*/

@media (--mobile-menu-start-point) {
	.header:not(.header-mobile) {
		.burger,
		.menu-toggle {
			display: flex;
		}
		.navbar-nav {
			position: fixed;
			right: 0;
			top: 0;
			width: 100%;
			max-width: 26.75rem;
			/*height: calc((var(--vh, 1vh) * 100) - var(--header-height));*/
			height: calc((var(--vh, 1vh) * 100) - var(--header-height));
			min-width: 320px;
			margin-top: var(--header-height);
			padding-top: 30px;
			background: var(--black);
			padding-right: 5px;
			transition: transform 0.25s ease-in-out;

			overflow-y: auto;
			overflow-x: hidden;
			@mixin scrollbar;

			&.nav-slide-left {
				right: auto;
				left: 0;
				transform: translateX(-100%);
				&.active {
					transform: translateX(0);
				}
			}
			&.nav-slide-right {
				right: 0;
				left: auto;
				transform: translateX(100%);
				&.active {
					transform: translateX(0);
				}
			}
			&.nav-slide-down {
				right: auto;
				left: 0;
				transform: translateY(-100%);
				&.active {
					transform: translateY(0);
				}
			}
			&.nav-slide-up {
				right: auto;
				left: 0;
				transform: translateY(100%);
				&.active {
					transform: translateY(0);
				}
			}

			.menu {
				width: 100%;
				max-height: 100%;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				align-self: center;
			}
		}
		.dropdown-menu {
			position: relative;
			display: block;
			overflow: hidden;
			top: 0;
			max-height: 0;
			transition: max-height ease .4s, margin-top .4s ease;

			&.active {
				max-height: 400px;
			}
		}
	}
}
/* end of Common desktop header Mobile HEADER */

/*----------------------- ONLY Mobile HEADER -----------------------*/
.header.header-mobile {
	.burger {
		display: flex;
	}
	.navbar-nav {
		position: fixed;
		right: 0;
		top: 0;
		width: 50%;
		height: 100vh;
		min-width: 320px;
		padding-top: 100px;
		background: var(--bg-second);
		padding-right: 5px;
		padding-bottom: 40px;
		transition: transform 0.3s ease-out;
		&.nav-slide-left {
			right: auto;
			left: 0;
			transform: translateX(-100%);
			&.active {
				transform: translateX(0);
			}
		}
		&.nav-slide-right {
			right: 0;
			left: auto;
			transform: translateX(100%);
			&.active {
				transform: translateX(0);
			}
		}
		&.nav-slide-down {
			right: auto;
			left: 0;
			transform: translateY(-100%);
			&.active {
				transform: translateY(0);
			}
		}
		&.nav-slide-up {
			right: auto;
			left: 0;
			transform: translateY(100%);
			&.active {
				transform: translateY(0);
			}
		}

		.menu {
			width: 100%;
			max-height: 100%;
			overflow-y: auto;
			flex-direction: column;
			justify-content: flex-start;
			padding-left: 40px;
			align-items: flex-start;
			align-self: center;

			&::-webkit-scrollbar {
				width: 3px;
				height: 2px;
				background: rgba(255, 255, 255, 0.1);
				border-radius: 3px;
			}

			&::-webkit-scrollbar-thumb {
				background: rgba(255, 255, 255, 0.5);
				border-radius: 3px;
				cursor: pointer;
			}
		}
		.menu-link {
			padding: 10px 0;
			font-size: ac(25px, 18px);
		}
	}
	.dropdown-menu {
		position: relative;
		top: 0;
	}
}
/* end of ONLY Mobile HEADER */

/*----------------------- HEADER COMPONENTS -----------------------*/
.burger,
.menu-toggle {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 2.25rem;
	height: 2.25rem;

	border: solid 1px var(--gray-300);

	cursor: pointer;

	span {
		width: 18px;
		height: 2px;
		background: linear-gradient(
			to right,
			var(--white) 0%,
			var(--white) 66.66667%,
			transparent 66.66668%,
			transparent 100%
		);
		transition: all 0.1s;
		position: relative;

		&::before,
		&::after {
			position: absolute;
			content: "";
			left: 0;
			width: 18px;
			height: 2px;
			background: var(--white);

			transition: all 0.1s;
		}

		&::before {
			top: -7px;
		}

		&::after {
			bottom: -7px;
		}
	}

	@mixin transition-all;

	@mixin min-media 1025 {
		&:hover {
			transform: scale(1.1);
		}
	}

	&.active {
		span {
			background: transparent;

			&::before {
				top: 0;
				transform: rotate(-45deg);
			}

			&::after {
				top: 0;
				transform: rotate(45deg);
			}
		}
	}
}

.header-close-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 77;
	background: rgba(0, 0, 0, 0.7);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;

	@mixin transition-all;

	&.active {
		visibility: visible;
		pointer-events: auto;
		opacity: 1;
		cursor: pointer;
	}
}

/* end of HEADER COMPONENTS*/
