.content-block {
	* {
		&:last-child {
			margin-bottom: 0;
		}
	}

	h1,
	h2,
	h3 {
		font-weight: 400;
		margin-bottom: 10px;
		color: var(--white);
		font-family: var(--font-serif);

		&:not(:first-child) {
			margin-top: 20px;
		}
	}

	h1 {
		font-size: ac(28px, 24px);
		font-weight: 700;
	}

	h2 {
		font-size: ac(24px, 20px);
		font-weight: 600;
	}

	h3 {
		font-size: ac(20px, 18px);
		font-weight: 500;
	}

	p {
		font-size: ac(15px, 14px);
		padding: 0;
		margin-bottom: 20px;
	}

	ul,
	ol,
	li {
		margin-bottom: 20px;
	}

	ul {
		list-style: disc inside;
	}

	ol {
		list-style: decimal inside;
	}

	li,
	blockquote {
		font-family: var(--font-serif);
		font-size: ac(15px, 14px);
		font-weight: 500;

		line-height: 161.02%;

		margin-bottom: 0;

		@mixin media 650 {
			line-height: 175%;
		}
	}

	blockquote {
		font-style: italic;
		position: relative;
		padding-left: ac(25px, 14px);
		margin-bottom: 40px;

		&::before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: ac(4px, 2px);
			border-radius: 10px;
			background: var(--white);
		}
	}

	img {
		border-radius: ac(40px, 20px);
		margin-top: ac(20px, 10px);
		margin-bottom: ac(76px, 35px);
	}

	p, li {
		a {
			font: inherit;
			color: var(--orange);
			text-decoration: underline;
			text-decoration-color: var(--bronze);
			text-decoration-thickness: 2px;
			text-decoration-skip-ink: none;
			transition: color .25s ease, text-decoration-color .25s ease;

			&:hover {
				color: var(--bronze);
				text-decoration-color: var(--primary);
			}
		}
	}
}