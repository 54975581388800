.choices__inner {
	background: var(--black);
	border: 2px solid var(--orange);
	padding: 6px 10px 6px 4px !important;
	border-radius: 0;
}

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
	font-size: 12px;
}

.choices[data-type*=select-one]::after {
	font-family: 'icomoon', serif;
	content: "\e90a";
	border: none;

	height: 24px;
	width: 24px;

	font-size: 24px;
	color: #fff;

	top: 50%;
	transform: translateY(-50%);

	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 0;

	transition: ease-in-out 0.25s;
}

.choices[data-type*=select-one].is-open::after {
	margin-top: 0;
	border: none;

	transform: translateY(-50%) rotate(180deg);
}

.choices__list--dropdown, .choices__list[aria-expanded] {
	background: var(--black);

	border-radius: 0;
	border: solid 2px var(--orange) !important;

	z-index: 99;
}

.choices[data-type*=select-one] .choices__input {
	border-bottom-color: var(--bronze);
	background: var(--black);

	color: var(--white);
}

.is-open .choices__inner {
	border-radius: 0;
}

.is-open, .is-focused {
	.choices__inner {
		border-color: var(--orange);
	}
}

.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
	background-color: var(--cocoa);
}

.choices__placeholder {
	opacity: 1;
}

@media (min-width: 640px) {
	.choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
		padding-right: 80px;
	}
}
