:root {
	--white: #ffffff;
	--black: #353030;
	--gray-100: #e4e4e4;
	--gray-200: #c4c5c7;
	--gray-300: #9fa0a2;
	--ivory: #f7f7f7;
	--cocoa: #5e5448;
	--orange: #f5a623;
	--primary: #a16026;
	--bronze: #bd9468;
	--red: #dc534f;
	--black-cow: #504949;
	--dust: #bc9468;

	--font-sans: "Rubik", sans-serif;
	--font-serif: "IBM Plex Serif", sans-serif;

	/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
	--mobile-menu-start-point: 1279;

	--header-height-full: ac(8rem, 4.5rem);
	--header-height-scrolled: ac(5rem, 3rem);
	--header-height: var(--header-height-full);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1279px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1280px);
