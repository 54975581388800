@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?mvd4rz');
    src:  url('../fonts/icomoon.eot?mvd4rz#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?mvd4rz') format('woff2'),
    url('../fonts/icomoon.ttf?mvd4rz') format('truetype'),
    url('../fonts/icomoon.woff?mvd4rz') format('woff'),
    url('../fonts/icomoon.svg?mvd4rz#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-thumbs-arrow-right:before {
    content: "\e90d";
}
.icon-thumbs-arrow-left:before {
    content: "\e90e";
}
.icon-pagination-arrow-right:before {
    content: "\e90b";
}
.icon-pagination-arrow-left:before {
    content: "\e90c";
}
.icon-arrow-select:before {
    content: "\e90a";
}
.icon-gmail:before {
    content: "\e904";
}
.icon-map-pin:before {
    content: "\e908";
}
.icon-phone:before {
    content: "\e909";
}
.icon-arrow-dropdown:before {
    content: "\e900";
}
.icon-arrow-left:before {
    content: "\e901";
}
.icon-arrow-right:before {
    content: "\e902";
}
.icon-search:before {
    content: "\e907";
}
.icon-facebook:before {
    content: "\e903";
}
.icon-instagram:before {
    content: "\e905";
}
.icon-linkedin:before {
    content: "\e906";
}
